import { RowData, Table } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useWindowSize } from 'react-use';
import {
  ColumnMeta,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  InfiniteScrollingOptions,
  TableStickyProps,
} from '../../constant';

export type UseTableHandlerProps<RD extends RowData> = TableStickyProps & {
  table: Table<RD>;
  infiniteScrollingOptions?: InfiniteScrollingOptions;
  limitOptions?: string[];
  isScrollableActionVisible?: boolean;
  rightArrowOffset?: number;
};

export default function useTableHandler<RD extends RowData>({
  table,
  infiniteScrollingOptions,
  limitOptions = ['10', '25', '50', '100'],
  isScrollableActionVisible,
  stickyColumn,
  stickyColumnNumber = stickyColumn ? 2 : 0,
}: UseTableHandlerProps<RD>) {
  const { height } = useWindowSize();

  // #region VALUES
  const headerGroups = table.getHeaderGroups();
  const { rows } = table.getRowModel();
  const pageLimit = table.getState().pagination.pageSize || DEFAULT_PAGE_SIZE;
  const pageCurrent = table.getState().pagination.pageIndex || DEFAULT_PAGE;
  const dataTotal = table.getPageCount();
  const pageTotal = Math.ceil(dataTotal / pageLimit);
  const hasNextPage = rows.length < dataTotal;

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const paginationRef = createRef<HTMLDivElement>();
  const tableHeaderRef = useRef<HTMLTableSectionElement | null>(null);
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);

  const [init, setInit] = useState<number | undefined>(undefined);
  const [paginationDropdown, setPaginationDropdown] = useState(false);
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(true);
  const [isTableArrowVisible, setIsTableArrowVisible] = useState(false);

  const virtualizer = useVirtualizer({
    count: hasNextPage ? rows.length + 1 : rows.length,
    getScrollElement: () => tableContainerRef.current as HTMLDivElement,
    estimateSize: () =>
      infiniteScrollingOptions ? Number(infiniteScrollingOptions.height) : 0,
  });

  const paginationOptions = useMemo(
    () =>
      limitOptions.map((option) => ({
        label: option,
        value: option,
      })),
    [limitOptions],
  );

  const showingLeft = (pageCurrent - 1) * pageLimit + 1;
  const showingRight =
    pageCurrent * pageLimit < dataTotal ? pageCurrent * pageLimit : dataTotal;

  const tableHeaderHeight = tableHeaderRef.current?.clientHeight || 0;
  const [tableContainerHeight, setTableContainerHeight] = useState(0);

  const overflowXIndicatorStyle = useMemo(() => {
    if (!init || !tableContainerRef.current) return {};

    const { innerHeight } = window;

    return {
      top: Math.min(
        innerHeight / 2 - (init || 0) + 70,
        tableContainerHeight / 2,
      ),
    };
  }, [init, tableContainerHeight]);

  // #endregion

  // #region TABLE HORIZONTAL SCROLL ACTION
  const handleScrollTable = useCallback(() => {
    if (!tableContainerRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = tableContainerRef.current;
    setIsLeftArrowVisible(scrollLeft > 0);
    setIsRightArrowVisible(scrollLeft + clientWidth < scrollWidth - 50);
  }, []);

  useEffect(() => {
    if (!(isScrollableActionVisible && rows.length > 0)) return;

    const tableContainerElement = tableContainerRef.current;
    tableContainerElement?.addEventListener('scroll', handleScrollTable);

    handleScrollTable();

    return () => {
      tableContainerElement?.removeEventListener('scroll', handleScrollTable);
    };
  }, [isScrollableActionVisible, handleScrollTable, rows.length]);

  // #endregion

  useEffect(() => {
    if (!tableContainerRef?.current || typeof window === 'undefined') return;
    if (init === undefined && top !== undefined) {
      const { top } = tableContainerRef.current.getBoundingClientRect();
      setInit(top);
    }
  }, [init]);

  //#region TABLE VERTICAL SCROLL ACTION
  useEffect(() => {
    if (!tableContainerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setTableContainerHeight(tableContainerRef?.current?.clientHeight ?? 0);
    });
    resizeObserver.observe(tableContainerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const handleScroll = useCallback(() => {
    if (!tableContainerRef?.current || typeof window === 'undefined') return;

    const { top, bottom } = tableContainerRef.current.getBoundingClientRect();
    const { innerHeight } = window;

    const isTopVisible = top < innerHeight / 2;

    if (!isTableArrowVisible && isTopVisible) {
      setIsTableArrowVisible(true);
      return;
    }

    if (isTableArrowVisible && !isTopVisible) {
      setIsTableArrowVisible(false);
    }
  }, [isTableArrowVisible]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [handleScroll]);
  //#endregion

  const leftArrowLeftPosition = stickyColumnNumber
    ? headerGroups?.[headerGroups.length - 1].headers
        ?.slice(0, stickyColumnNumber)
        .reduce(
          (acc, cur) =>
            acc +
            cur.getSize() +
            ((cur.column.columnDef.meta as ColumnMeta)?.sizeOffset ?? 0),
          0,
        )
    : (headerGroups?.[0].headers?.[0]?.column.columnDef.meta as ColumnMeta)
        ?.sizeOffset ?? 0;

  return {
    tableContainerRef,
    tableContainerHeight,
    leftArrowLeftPosition,
    tableHeaderHeight,
    rows,
    isLeftArrowVisible,
    isRightArrowVisible,
    overflowXIndicatorStyle,
    headerGroups,
    tableHeaderRef,
    tableBodyRef,
    virtualizer,
    hasNextPage,
    paginationRef,
    pageLimit,
    paginationDropdown,
    paginationOptions,
    showingLeft,
    showingRight,
    dataTotal,
    isTableArrowVisible,
    pageCurrent,
    pageTotal,
    setPaginationDropdown,
  };
}
